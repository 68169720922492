import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

const ToolsDesigningServices = props => (
  <Layout>
    <Helmet
      title="Tools for designing services"
      meta={[
        {
          name:
            'Article about my experience during a talk given to a group of start-ups',
          content: 'Articles',
        },
      ]}
      bodyAttributes={{
        class: 'articles',
      }}
    />

    <div id="main" className="alt">
      <section id="article-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.coverToolsDS.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>
      <div class="post-wrapper">
        <div class="post-content">
          <header>
            <h1>tools for designing services.</h1>
            <h3>
              Giving a talk to a group of Engineers & Business experts; exposing
              them to the Service Design methodology and conducting a
              co-designing workshop to assist them in their start-up businesses.
            </h3>
          </header>
          <section>
            <small>SEPTEMBER 2016 - 8 mins read</small>
            <p className="full-text">
              An office colleague & me were invited to give a talk at the
              European Network of Design for Resilient Entrepreneurship (ENDuRE)
              program. Our aim was to provide a ‘hands-on’ approach to improve
              the resilience of “hard science” start-ups. This program was
              international and collaborative, and adopted a bespoke blended
              learning approach designed to engage real game-changers by
              equipping them with the all the skills, tools and confidence to
              successfully turn business ideas into reality.
            </p>
            <div className="image">
              <Img fluid={props.data.toolsds1.childImageSharp.fluid} alt="" />
            </div>

            <div className="full-text">
              <div className="article">
                <div className="article-left">
                  It was held at the University of Pisa in Italy, where we had
                  to give a talk and hold an interactive workshop. The
                  participants were people with Engineering, Technical &
                  Business backgrounds who were founders of small start-ups. We
                  aimed to educate these people with the tools to look at their
                  start-up/businesses from a user-centric design point of view
                  in order to minimise the probability of failure of start-ups
                  by helping them grow quickly and overcome the “Valley of
                  Death”​
                </div>
                <div className="article-right">
                  <Img
                    fluid={props.data.toolsds2.childImageSharp.fluid}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <p className="full-text">
              First involved the preparation of all the material that will be
              required. This included preparation of presentation for talk,
              planning the structure of the workshop & the design of canvases to
              be used to co-create with the participants. On the day, I gave an
              introductory talk about service design, common terminology, the
              different tools and why & how they are to be used.
            </p>
            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.toolsds3.childImageSharp.fluid} alt="" />
              </div>
              <div className="img right">
                <Img fluid={props.data.toolsds4.childImageSharp.fluid} alt="" />
              </div>
            </div>
            <p className="full-text">
              Here are some of the highlights of the content that was introduced
              that day: First step involves need to do all the primary and
              secondary research. After this research, people end up with a
              large amount of data about various different things and can end up
              getting lost in all the mess.
            </p>
            <p className="full-text">
              These service design tools thus serve the purpose taking all that
              unorganised research mess and help you to organise/structure it in
              such a way that it makes it easy to analyse it so that you can
              capture the right insights from the research. These insights then
              aid in designing a user-centric service concept.
            </p>
            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.toolsds5.childImageSharp.fluid} alt="" />
              </div>
              <div className="img right">
                <Img fluid={props.data.toolsds6.childImageSharp.fluid} alt="" />
              </div>
            </div>
            <h4>Stakeholder mapping </h4>
            <div className="full-text">
              <ol>
                <li>
                  To understand who your key stakeholders are, where they come
                  from, and what they are looking for in relationship to your
                  service
                </li>
                <li>
                  Identify the level of influence the different stakeholders
                  have on your main target user group and on your service.
                </li>
              </ol>
            </div>
            <h4>Systems map</h4>
            <div className="full-text">
              <ol>
                <li>
                  To identify how the different key stakeholders/actors are
                  linked to one another
                </li>
                <li>
                  To identify the flows between the key stakeholders/actors{' '}
                </li>
              </ol>
            </div>
            <h4>Personas</h4>
            <div className="full-text">
              <ol>
                <li>Package user research into an easy to understand format</li>
                <li>
                  Maintain focus on the needs of users during the developing the
                  service
                </li>
                <li>
                  Test and evaluate ideas and service concepts against these
                  needs.
                </li>
              </ol>
            </div>
            <h4>Journey maps </h4>
            <div className="full-text">
              <ol>
                <li>Identify the key elements of a service</li>
                <li>
                  Understand the links between all the different elements over
                  time
                </li>
                <li>
                  Identify problem areas in a service or areas where new things
                  can be added
                </li>
                <li>Create empathy with different types of users.</li>
              </ol>
            </div>
            <h4>Touchpoints</h4>
            <div className="full-text">
              <ol>
                <li>
                  They are any point of contact between a Customer and the
                  Service Provider
                </li>
                <li>
                  By understanding the human need at a moment in time, we can
                  propose a touchpoint with a clear value proposition to the
                  Customer.
                </li>
              </ol>
            </div>
            <h4>Storyboards</h4>
            <div className="full-text">
              <ol>
                <li>
                  To visualise the entire service experience and explain the
                  features and value of a service in a visual format
                </li>
                <li>
                  It not only shows the functional aspects of the service but
                  also makes it clear how the service brings in emotional and
                  social values.
                </li>
              </ol>
            </div>
            <h4>Pirates AARRR!</h4>
            <p className="full-text">
              Acquisition - users come to the site from various channels
              Activation - user register and enjoy 1st visit Retention - users
              come back and use the service multiple times Referral - users
              recommend the service to others Revenue - users conduct some
              monetization behavious
            </p>
            <div className="image">
              <Img fluid={props.data.toolsds7.childImageSharp.fluid} alt="" />
            </div>
            <p className="full-text">
              The talks were followed up by an interactive workshop. In this
              workshop we helped the participants to apply the tools they just
              learnt earlier in the context for their startup/business. We
              guided them on how they can develop a holistic framework that will
              transform their ideas into user-centric & economically viable
              businesses.​
            </p>
            <p className="full-text">
              All of them really appreciated what they learnt from this session,
              especially the persona and customer journey mapping tools.
            </p>
          </section>
          <section className="takeaways">
            <span class="project-step-number">01</span>
            <h2>takeaways</h2>
            <p className="full-text">
              Practising service design in the field is a lot different than
              teaching about service design. This experience made me really
              think more deeply about service design and really question the
              reason behind using each tool and how it helps in designing a
              holistic and meaningful service.
            </p>
          </section>
          {/* <section className="article-social">
            <div className="social inner">
              <p className="article-social-heading">share this post</p>
              <ul className="icons">
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://twitter.com/sagarsatam13"
                    className="icon alt fa-twitter"
                  >
                    <span className="label">Twitter</span>
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/sagar.satam/"
                    className="icon alt fa-instagram"
                  >
                    <span className="label">Instagram</span>
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://in.pinterest.com/sagarsatam/"
                    className="icon alt fa-pinterest"
                  >
                    <span className="label">Pinterest</span>
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/in/satamsagar/"
                    className="icon alt fa-linkedin"
                  >
                    <span className="label">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </section> */}
          <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to all
              <br />
              <a href="/my-thinking"> my thinking</a>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <div className="tiles__next">next story</div>
        <div className="tiles__wrapper">
          <div className="tiles__content">
            <div className="tiles__info">
              <a href="/articles/service-design-post-its">
                <h2 className="tiles__title">service design & post-its.</h2>
              </a>
              <small>JANUARY 2016 ⠂3 mins read</small>
              <div className="tiles__text">
                Nothing says creativity better than a crazy colourful mess of
                Post-its smeared onto the wall rather than a bunch of people
                with suits sitting and talking around the table.
              </div>
            </div>
          </div>
          <div className="tiles__image">
            <a href="/articles/service-design-post-its">
              <Img fluid={props.data.otherarticle.childImageSharp.fluid} />
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ToolsDesigningServices

export const fluidToolsDSImage = graphql`
  fragment fluidToolsDSImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    coverToolsDS: file(
      relativePath: { eq: "articles/toolsds/cover-toolsds.png" }
    ) {
      ...fluidToolsDSImage
    }
    toolsds1: file(relativePath: { eq: "articles/toolsds/toolsds1.png" }) {
      ...fluidToolsDSImage
    }
    toolsds2: file(relativePath: { eq: "articles/toolsds/toolsds2.png" }) {
      ...fluidToolsDSImage
    }
    toolsds3: file(relativePath: { eq: "articles/toolsds/toolsds3.png" }) {
      ...fluidToolsDSImage
    }
    toolsds4: file(relativePath: { eq: "articles/toolsds/toolsds4.png" }) {
      ...fluidToolsDSImage
    }
    toolsds5: file(relativePath: { eq: "articles/toolsds/toolsds5.png" }) {
      ...fluidToolsDSImage
    }
    toolsds6: file(relativePath: { eq: "articles/toolsds/toolsds6.png" }) {
      ...fluidToolsDSImage
    }
    toolsds7: file(relativePath: { eq: "articles/toolsds/toolsds7.png" }) {
      ...fluidToolsDSImage
    }
    otherarticle: file(relativePath: { eq: "projects/postit.png" }) {
      ...fluidToolsDSImage
    }
  }
`
